import { Check, Close } from '@mui/icons-material';
import {
  Card,
  ClickAwayListener,
  IconButton,
  Popper,
  Stack,
  TextField,
  TextFieldProps,
} from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';

export default function EditableTextField(
  props: Omit<TextFieldProps, 'onChange'> & {
    // eslint-disable-next-line no-unused-vars
    onChange?: (v: string) => Promise<void>;
  },
) {
  const { value, onChange, disabled: propsDisabled, ...rest } = props;
  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);
  const [rawValue, setRawValue] = useState<string>(`${value}`);
  const timeNow = useRef(performance.now());
  const [disabled, setDisabled] = useState(false);
  const [errored, setErrored] = useState(false);

  useEffect(() => {
    setRawValue(`${value}`);
    setDisabled(false);
    setErrored(false);
  }, [value]);

  return (
    <>
      <ClickAwayListener
        onClickAway={() => {
          if (
            rawValue === `${value}` &&
            performance.now() - timeNow.current > 100
          ) {
            setAnchorEl(null);
          }
        }}
      >
        <Popper open={!!anchorEl} anchorEl={anchorEl} placement="bottom-end">
          <Stack gap={0.5} direction="row">
            <Card>
              <IconButton
                size="small"
                onClick={async () => {
                  const capturedValue = rawValue;
                  setAnchorEl(null);
                  setRawValue(`${value}`);
                  setDisabled(true);
                  try {
                    await onChange?.(capturedValue);
                  } catch {
                    setDisabled(false);
                    setErrored(true);
                  }
                }}
              >
                <Check />
              </IconButton>
            </Card>
            <Card>
              <IconButton
                size="small"
                onClick={() => {
                  setAnchorEl(null);
                  setRawValue(`${value}`);
                  setErrored(false);
                }}
              >
                <Close />
              </IconButton>
            </Card>
          </Stack>
        </Popper>
      </ClickAwayListener>
      <TextField
        variant="standard"
        {...rest}
        multiline
        fullWidth
        error={errored}
        helperText={
          disabled ? 'Saving...' : errored ? 'Error' : rest.helperText
        }
        disabled={disabled || propsDisabled}
        value={rawValue}
        onChange={(e) => anchorEl && setRawValue(e.target.value)}
        onClick={(e) => {
          timeNow.current = performance.now();
          setAnchorEl(e.currentTarget);
        }}
      />
    </>
  );
}
