import {
  AccountTree,
  Add,
  AttachMoney,
  InfoOutlined,
  PlaylistAddCheck,
  Remove,
  RemoveCircleOutline,
  ShoppingCart,
} from '@mui/icons-material';
import {
  Autocomplete,
  Breadcrumbs,
  Button,
  Checkbox,
  Chip,
  IconButton,
  Link,
  List,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  Menu,
  MenuItem,
  Stack,
  Tab,
  Tabs,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import { DateTimePicker } from '@mui/x-date-pickers-pro';
import {
  updateEntity,
  useCommodities,
  useEntity,
  useEntityChildren,
} from 'api/entity';
import { useCreateEntityModal } from 'components/createEntity';
import EditableTextField from 'components/editableTextField';
import EntityView from 'components/entityView';
import NumberInput from 'components/NumberInput';
import { PriorityIconsReversed } from 'components/projectView';
import moment from 'moment';
import React, { useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  FinsightEntity,
  isCommodityEntity,
  isTransactionEntity,
  Statuses,
  TransactionCommodityModel,
} from 'shared';

// const showDollar = Intl.NumberFormat('en-us', {
//   currency: 'usd',
//   style: 'currency',
//   trailingZeroDisplay: 'stripIfInteger',
// });

export function View() {
  const params = useParams();
  const { data: project } = useEntity(params.id);
  const { data: children } = useEntityChildren(params.id);

  const { showCreateEntityModal } = useCreateEntityModal();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [tab, setTab] = useState(0);

  const subProjects = useMemo(
    () => children?.filter((child) => child.type === 'project'),
    [children],
  );

  const tasks = useMemo(
    () => children?.filter((child) => child.type === 'task'),
    [children],
  );

  const transactions = useMemo(
    () => children?.filter((child) => child.type === 'transaction'),
    [children],
  );

  const commodities = useMemo(
    () => children?.filter((child) => child.type === 'commodity'),
    [children],
  );

  if (!project) return <span>NOT FOUND</span>;

  return (
    <Stack pt={3} gap={2}>
      <Stack direction="row" alignItems="center" justifyContent="space-between">
        <Stack direction="row" gap={1}>
          <Chip variant="outlined" size="small" label={project.type} />
          {project.parents?.length && (
            <Breadcrumbs>
              {Array.from(project.parents)
                .reverse()
                .map((parent) => (
                  <Link key={parent.id} href={`/entity/${parent.id}`}>
                    {parent.name}
                  </Link>
                ))}
            </Breadcrumbs>
          )}
        </Stack>
        {project.type !== 'transaction' && project.type !== 'commodity' && (
          <Button
            startIcon={<Add />}
            onClick={(e) => setAnchorEl(e.currentTarget)}
          >
            New
          </Button>
        )}
      </Stack>
      <Stack gap={4} direction="row" justifyContent="space-between">
        <Stack gap={2} flexGrow={1} minWidth={0}>
          <EditableTextField
            fullWidth
            value={project.name}
            onChange={async (v) => {
              await updateEntity(project.id, { name: v });
            }}
            inputProps={{
              sx: {
                fontSize: 'h4.fontSize',
                lineHeight: '1em',
              },
            }}
          />
          {isCommodityEntity(project) && (
            <>
              <EditableTextField
                fullWidth
                label="Brand"
                value={project.commodity_brand}
                onChange={async (v) => {
                  await updateEntity(project.id, { commodity_brand: v });
                }}
                variant="outlined"
              />
              <NumberInput
                label="Price per unit"
                startAdornment="$"
                value={project.commodity_price_per_unit}
                onChange={(_, v) => {
                  updateEntity(project.id, {
                    commodity_price_per_unit: v ?? undefined,
                  });
                }}
              />
              <EditableTextField
                fullWidth
                label="Image URL"
                value={project.commodity_image_url}
                onChange={async (v) => {
                  await updateEntity(project.id, { commodity_image_url: v });
                }}
                variant="outlined"
              />
            </>
          )}
          {isTransactionEntity(project) && (
            <>
              <Stack
                direction="row"
                alignItems="center"
                gap={2}
                sx={{
                  '& > *': {
                    flexGrow: 1,
                  },
                }}
              >
                <NumberInput
                  label="Transaction Amount"
                  startAdornment="$"
                  value={project.transaction_amount}
                  onChange={(_, v) => {
                    updateEntity(project.id, {
                      transaction_amount: v ?? undefined,
                    });
                  }}
                />
                <NumberInput
                  label="Transaction Other Costs"
                  startAdornment="$"
                  value={project.transaction_othercosts}
                  onChange={(_, v) => {
                    updateEntity(project.id, {
                      transaction_othercosts: v ?? undefined,
                    });
                  }}
                />
              </Stack>
              <Stack direction="row" alignItems="center" gap={2}>
                <EditableTextField
                  fullWidth
                  label="Transaction Buyer"
                  variant="outlined"
                  value={project.transaction_buyer}
                  onChange={async (v) => {
                    await updateEntity(project.id, { transaction_buyer: v });
                  }}
                />
                <EditableTextField
                  fullWidth
                  label="Transaction Seller"
                  variant="outlined"
                  value={project.transaction_seller}
                  onChange={async (v) => {
                    await updateEntity(project.id, { transaction_seller: v });
                  }}
                />
              </Stack>
            </>
          )}
          {project.type !== 'transaction' && project.type !== 'commodity' && (
            <>
              <EditableTextField
                fullWidth
                label="Description"
                variant="outlined"
                value={project.description ?? ''}
                onChange={async (v) => {
                  await updateEntity(project.id, { description: v });
                }}
                multiline
              />
              <Stack direction="row" overflow="auto" gap={2} pb={1}>
                {subProjects?.map((x) => (
                  <EntityView key={x.id} entity={x} viewType="card" />
                ))}
              </Stack>

              <Tabs value={tab} onChange={(_, v) => setTab(v)}>
                <Tab label="Tasks" value={0} />
                <Tab label="Transaction" value={1} />
                <Tab label="Commodities" value={2} />
                <Tab label="Documents" value={3} />
              </Tabs>
              <Typography>Tasks</Typography>
              {tab === 0 && (
                <List>
                  {tasks?.map((x) => <EntityView key={x.id} entity={x} />)}
                </List>
              )}
              {tab === 1 && (
                <List>
                  {transactions?.map((x) => (
                    <EntityView key={x.id} entity={x} />
                  ))}
                </List>
              )}
              {tab === 2 && (
                <List>
                  {commodities?.map((x) => (
                    <EntityView key={x.id} entity={x} />
                  ))}
                </List>
              )}
            </>
          )}
        </Stack>
        <Stack gap={2} flexBasis={350} flexShrink={0}>
          {project.type !== 'transaction' && project.type !== 'commodity' && (
            <TextField
              select
              fullWidth
              size="small"
              label="Priority"
              value={project.priority}
              onChange={(e) => {
                updateEntity(project.id, { priority: +e.target.value });
              }}
            >
              {PriorityIconsReversed.map((icon) => (
                <MenuItem key={icon.value} value={icon.value}>
                  <Stack direction="row" alignItems="center" gap={1}>
                    {icon?.icon}
                    {icon?.label}
                  </Stack>
                </MenuItem>
              ))}
            </TextField>
          )}
          {project.type !== 'commodity' && (
            <TextField
              size="small"
              label="Status"
              fullWidth
              select
              value={project.status}
              onChange={(e) => {
                updateEntity(project.id, { status: e.target.value });
              }}
            >
              {project.type === 'transaction'
                ? [
                    <MenuItem key={0} value="pending">
                      Pending
                    </MenuItem>,
                    <MenuItem key={0} value="paid">
                      Paid
                    </MenuItem>,
                    <MenuItem key={0} value="cancelled">
                      Cancelled
                    </MenuItem>,
                    <MenuItem key={0} value="refunded">
                      Refunded
                    </MenuItem>,
                  ]
                : Statuses.map((status) => (
                    <MenuItem key={status.value} value={status.value}>
                      {status.label}
                    </MenuItem>
                  ))}
            </TextField>
          )}
          {project.type === 'transaction' && (
            <DateTimePicker
              label="Created"
              value={project.created ? moment(project.created) : null}
              onChange={(v) => {
                updateEntity(project.id, { created: v?.toISOString() });
              }}
            />
          )}
          {isCommodityEntity(project) && (
            <EditableTextField
              fullWidth
              label="Unit"
              variant="outlined"
              value={project.commodity_unit}
              onChange={async (v) => {
                await updateEntity(project.id, { commodity_unit: v });
              }}
              multiline
            />
          )}
          {(project.type === 'transaction' || project.type === 'commodity') && (
            <EditableTextField
              fullWidth
              minRows={3}
              label="Description"
              variant="outlined"
              value={project.description ?? ''}
              onChange={async (v) => {
                await updateEntity(project.id, { description: v });
              }}
              multiline
            />
          )}
          {isTransactionEntity(project) && (
            <Stack gap={1} direction="row" alignItems="center">
              <Checkbox
                checked={project.transaction_is_debit}
                onChange={(e) =>
                  updateEntity(project.id, {
                    transaction_is_debit: e.target.checked,
                  })
                }
              />
              <Typography>Is Debit</Typography>
              <Tooltip
                title="A credit transaction (default) is when a purchase is made. A
              debit transaction (when this is checked) means this marks a sale
              and records money coming in."
              >
                <InfoOutlined sx={{ fontSize: '1em' }} />
              </Tooltip>
            </Stack>
          )}
          {project.type !== 'transaction' && project.type !== 'commodity' && (
            <>
              <DateTimePicker
                label="Deadline"
                value={project.deadline ? moment(project.deadline) : null}
                onChange={(v) => {
                  updateEntity(project.id, { deadline: v?.toISOString() });
                }}
              />
              <NumberInput
                label="Cost Estimate"
                startAdornment="$"
                value={project.cost_estimate}
                onChange={(_, v) => {
                  updateEntity(project.id, { cost_estimate: v ?? undefined });
                }}
              />
              <NumberInput
                label="Cost Maximum"
                startAdornment="$"
                value={project.cost_maximum}
                onChange={(_, v) => {
                  updateEntity(project.id, { cost_maximum: v ?? undefined });
                }}
              />
            </>
          )}
        </Stack>
      </Stack>

      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
      >
        {project.type === 'project' && (
          <MenuItem onClick={() => showCreateEntityModal('project', project)}>
            <ListItemIcon>
              <AccountTree />
            </ListItemIcon>
            <ListItemText primary="New Project" />
          </MenuItem>
        )}
        <MenuItem onClick={() => showCreateEntityModal('task', project)}>
          <ListItemIcon>
            <PlaylistAddCheck />
          </ListItemIcon>
          <ListItemText primary="New Task" />
        </MenuItem>
        <MenuItem onClick={() => showCreateEntityModal('transaction', project)}>
          <ListItemIcon>
            <AttachMoney />
          </ListItemIcon>
          <ListItemText primary="New Transaction" />
        </MenuItem>
        <MenuItem onClick={() => showCreateEntityModal('commodity', project)}>
          <ListItemIcon>
            <ShoppingCart />
          </ListItemIcon>
          <ListItemText primary="New Commodity" />
        </MenuItem>
      </Menu>
    </Stack>
  );
}

export function CommoditySearch(props: {
  value?: TransactionCommodityModel[];
  onChange?: (value: TransactionCommodityModel[]) => void;
}) {
  const { data: commodities } = useCommodities();
  const commodityMap = useMemo(
    () =>
      commodities?.reduce(
        (acc, x) => ((acc[x.id] = x), acc),
        {} as Record<string, FinsightEntity | undefined>,
      ) ?? {},
    [commodities],
  );

  const { finalItems, usedIds } = useMemo(() => {
    const usedIds = new Set();
    const finalItems =
      props.value?.map((x) => {
        usedIds.add(x.commodity);
        return {
          ...x,
          commodityEntity: commodityMap[x.commodity],
        };
      }) ?? [];

    return {
      finalItems,
      usedIds,
    };
  }, [commodityMap, props.value]);

  console.log(finalItems);

  return (
    <>
      <Autocomplete
        options={commodities?.filter((x) => !usedIds.has(x.id)) ?? []}
        getOptionLabel={(option) => option.name}
        value={[]}
        multiple
        onChange={(_, value) =>
          props.onChange?.([
            ...(props.value ?? []),
            {
              commodity: value[0].id,
              quantity: 1,
            },
          ])
        }
        renderInput={(params) => <TextField {...params} label="Commodity" />}
      />
      <List>
        {finalItems.map((x) => (
          <ListItem key={x.commodity}>
            <ListItemText
              primary={x.commodityEntity?.name ?? x.commodity}
              secondary={
                x.commodityEntity
                  ? isCommodityEntity(x.commodityEntity)
                    ? x.commodityEntity.commodity_brand
                    : ''
                  : ''
              }
            />
            <ListItemSecondaryAction>
              <IconButton
                onClick={() => {
                  props.onChange?.(
                    x.quantity === 1
                      ? finalItems.filter((y) => y !== x)
                      : finalItems.map((x) =>
                          x.commodity === x.commodity
                            ? { ...x, quantity: x.quantity - 1 }
                            : x,
                        ),
                  );
                }}
              >
                <Remove />
              </IconButton>
              <NumberInput
                variant="standard"
                value={x.quantity}
                sx={{ width: 50 }}
                onChange={(_, v) => {
                  props.onChange?.(
                    finalItems.map((y) =>
                      y.commodity === x.commodity
                        ? { ...y, quantity: Math.max(v ?? 1, 1) }
                        : y,
                    ),
                  );
                }}
              />
              <Typography display="inline">
                {x.commodityEntity
                  ? isCommodityEntity(x.commodityEntity)
                    ? `${x.commodityEntity.commodity_unit}${x.quantity > 1 ? 's' : ''}`
                    : ''
                  : ''}
              </Typography>
              <IconButton
                onClick={() => {
                  props.onChange?.(
                    finalItems.map((x) =>
                      x.commodity === x.commodity
                        ? { ...x, quantity: x.quantity + 1 }
                        : x,
                    ),
                  );
                }}
              >
                <Add />
              </IconButton>
              <IconButton
                color="error"
                onClick={() => {
                  props.onChange?.(finalItems.filter((y) => y !== x));
                }}
              >
                <RemoveCircleOutline />
              </IconButton>
            </ListItemSecondaryAction>
          </ListItem>
        ))}
      </List>
    </>
  );
}
