"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isCommodityEntity = isCommodityEntity;
exports.isTransactionEntity = isTransactionEntity;
function isCommodityEntity(entity) {
    return entity.type === 'commodity';
}
function isTransactionEntity(entity) {
    return entity.type === 'transaction';
}
