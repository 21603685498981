import { InfoOutlined } from '@mui/icons-material';
import {
  Button,
  Checkbox,
  Divider,
  MenuItem,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import { DateTimePicker } from '@mui/x-date-pickers-pro';
import { createEntity } from 'api/entity';
import { useCurrentModal, useModal } from 'components/modal';
import NumberInput from 'components/NumberInput';
import { PriorityIconsReversed } from 'components/projectView';
import moment, { Moment } from 'moment';
import { CommoditySearch } from 'pages/finsightEntity/finsightEntity.view';
import React, { useState } from 'react';
import { FinsightEntity, Statuses, TransactionCommodityModel } from 'shared';
import { useSWRConfig } from 'swr';

type CreateEntityProps = {
  type: string;
  parent?: FinsightEntity;
};

export function CreateEntityModal(props: CreateEntityProps) {
  const modalCtx = useCurrentModal();

  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [priority, setPriority] = useState(2);
  const [status, setStatus] = useState<string>(
    props.type === 'transaction' ? 'pending' : Statuses[0].value,
  );
  const [costEstimate, setCostEstimate] = useState(0);
  const [costMaximum, setCostMaximum] = useState(0);
  const [deadline, setDeadline] = useState<Moment | null>(null);
  const [created, setCreated] = useState<Moment | null>(moment());

  const [transactionAmount, setTransactionAmount] = useState(0);
  const [transactionOtherCosts, setTransactionOtherCosts] = useState(0);
  const [transactionBuyer, setTransactionBuyer] = useState('');
  const [transactionSeller, setTransactionSeller] = useState('');
  const [transactionIsDebit, setTransactionIsDebit] = useState(false);

  const [commodityBrand, setCommodityBrand] = useState('');
  const [commodityUnit, setCommodityUnit] = useState('');
  const [commodityPricePerUnit, setCommodityPricePerUnit] = useState(0);
  const [commodityImageUrl, setCommodityImageUrl] = useState('');

  const [commodities, setCommodities] = useState<TransactionCommodityModel[]>(
    [],
  );

  const [loading, setLoading] = useState(false);
  const { mutate, cache } = useSWRConfig();

  let canSubmit = true;

  if (props.type === 'transaction') {
    if (
      !transactionBuyer ||
      !transactionSeller ||
      transactionOtherCosts > transactionAmount
    ) {
      canSubmit = false;
    }
  }

  return (
    <Stack width="95vw" maxWidth="1000px" gap={2} pt={2} flexGrow={1}>
      <Typography variant="h6">
        {props.parent ? `Creating under ${props.parent.name}` : 'Root Level'}
      </Typography>
      <Stack direction="row" gap={2}>
        <Stack flexGrow={1} flexShrink={0} gap={2}>
          <Stack gap={2} direction="row" alignItems="center">
            <TextField
              label="Name"
              fullWidth
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
            {props.type === 'commodity' && (
              <TextField
                label="Brand"
                fullWidth
                value={commodityBrand}
                onChange={(e) => setCommodityBrand(e.target.value)}
              />
            )}
          </Stack>
          {props.type !== 'transaction' && props.type !== 'commodity' && (
            <TextField
              label="Description"
              fullWidth
              multiline
              minRows={5}
              value={description}
              onChange={(e) => setDescription(e.target.value)}
            />
          )}
          {props.type === 'commodity' && (
            <>
              <NumberInput
                label="Price per Unit"
                startAdornment="$"
                value={commodityPricePerUnit}
                onChange={(_, v) => setCommodityPricePerUnit(v ?? 0)}
              />
              <TextField
                label="Image URL"
                fullWidth
                value={commodityImageUrl}
                onChange={(e) => setCommodityImageUrl(e.target.value)}
              />
            </>
          )}
          {props.type === 'transaction' && (
            <>
              <Stack
                gap={2}
                direction="row"
                sx={{
                  '& > *': {
                    flexGrow: 1,
                  },
                }}
              >
                <NumberInput
                  label="Transaction Amount"
                  startAdornment="$"
                  value={transactionAmount}
                  onChange={(_, v) => setTransactionAmount(v ?? 0)}
                />
                <NumberInput
                  label="Transaction Other Costs"
                  startAdornment="$"
                  value={transactionOtherCosts}
                  onChange={(_, v) => setTransactionOtherCosts(v ?? 0)}
                />
              </Stack>
              <Stack gap={2} direction="row">
                <TextField
                  label="Transaction Buyer"
                  fullWidth
                  value={transactionBuyer}
                  onChange={(e) => setTransactionBuyer(e.target.value)}
                />
                <TextField
                  label="Transaction Seller"
                  fullWidth
                  value={transactionSeller}
                  onChange={(e) => setTransactionSeller(e.target.value)}
                />
              </Stack>
              <CommoditySearch
                value={commodities}
                onChange={(v) => setCommodities(v)}
              />
              <Divider />
              <Typography variant="h6">Transaction Summary</Typography>
              <Stack direction="row" justifyContent="space-between">
                <Stack>
                  <Typography>Bill to:</Typography>
                  <Typography variant="h6">{transactionBuyer}</Typography>
                </Stack>
                <Stack>
                  <Typography>Pay to:</Typography>
                  <Typography variant="h6">{transactionSeller}</Typography>
                </Stack>
              </Stack>
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
              >
                <Typography variant="h5">Other Costs:</Typography>
                <Typography variant="h5">${transactionOtherCosts}</Typography>
              </Stack>
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
              >
                <Typography variant="h4">Total:</Typography>
                <Typography variant="h4">${transactionAmount}</Typography>
              </Stack>
            </>
          )}
        </Stack>
        <Stack flexShrink={0} flexBasis={300} gap={2}>
          {props.type !== 'commodity' && (
            <Stack direction="row" gap={2}>
              {props.type !== 'transaction' && (
                <TextField
                  select
                  fullWidth
                  size="small"
                  variant="standard"
                  label="Priority"
                  value={priority}
                  onChange={(e) => setPriority(+e.target.value)}
                >
                  {PriorityIconsReversed.map((icon) => (
                    <MenuItem key={icon.value} value={icon.value}>
                      <Stack direction="row" alignItems="center" gap={1}>
                        {icon?.icon}
                        {icon?.label}
                      </Stack>
                    </MenuItem>
                  ))}
                </TextField>
              )}

              <TextField
                size="small"
                variant="standard"
                label="Status"
                fullWidth
                select
                value={status}
                onChange={(e) => setStatus(e.target.value as any)}
              >
                {props.type === 'transaction'
                  ? [
                      <MenuItem key={0} value="pending">
                        Pending
                      </MenuItem>,
                      <MenuItem key={0} value="paid">
                        Paid
                      </MenuItem>,
                      <MenuItem key={0} value="cancelled">
                        Cancelled
                      </MenuItem>,
                      <MenuItem key={0} value="refunded">
                        Refunded
                      </MenuItem>,
                    ]
                  : Statuses.map((status) => (
                      <MenuItem key={status.value} value={status.value}>
                        {status.label}
                      </MenuItem>
                    ))}
              </TextField>
            </Stack>
          )}
          {props.type === 'commodity' && (
            <>
              <TextField
                label="Unit"
                fullWidth
                value={commodityUnit}
                onChange={(e) => setCommodityUnit(e.target.value)}
              />
            </>
          )}
          {props.type !== 'transaction' && props.type !== 'commodity' && (
            <DateTimePicker
              label="Deadline"
              value={deadline}
              onChange={(v) => setDeadline(v)}
            />
          )}
          {props.type === 'transaction' && (
            <DateTimePicker
              label="Created"
              value={created}
              onChange={(v) => setCreated(v)}
            />
          )}
          {(props.type === 'transaction' || props.type === 'commodity') && (
            <TextField
              label="Description"
              fullWidth
              multiline
              minRows={5}
              value={description}
              onChange={(e) => setDescription(e.target.value)}
            />
          )}
          {props.type === 'transaction' && (
            <Stack gap={1} direction="row" alignItems="center">
              <Checkbox
                checked={transactionIsDebit}
                onChange={(e) => setTransactionIsDebit(e.target.checked)}
              />
              <Typography>Is Debit</Typography>
              <Tooltip
                title="A credit transaction (default) is when a purchase is made. A
                debit transaction (when this is checked) means this marks a sale
                and records money coming in."
              >
                <InfoOutlined sx={{ fontSize: '1em' }} />
              </Tooltip>
            </Stack>
          )}
          {props.type !== 'transaction' && props.type !== 'commodity' && (
            <>
              <NumberInput
                label="Cost Estimate"
                startAdornment="$"
                value={costEstimate}
                onChange={(_, v) => setCostEstimate(v ?? 0)}
              />
              <NumberInput
                label="Cost Maximum"
                startAdornment="$"
                value={costMaximum}
                onChange={(_, v) => setCostMaximum(v ?? 0)}
              />
            </>
          )}
        </Stack>
      </Stack>
      <Stack direction="row" justifyContent="flex-end">
        <Button color="error" onClick={() => modalCtx?.closeModal()}>
          Cancel
        </Button>
        <Button
          disabled={loading || !canSubmit}
          variant="outlined"
          onClick={async () => {
            setLoading(true);
            try {
              await createEntity({
                type: props.type,
                name,
                description,
                priority,
                status,
                cost_estimate: costEstimate,
                cost_maximum: costMaximum,
                deadline: deadline?.toISOString(),
                parent: props.parent?.id,
                ...(props.type === 'transaction'
                  ? {
                      transaction_amount: transactionAmount,
                      transaction_buyer: transactionBuyer,
                      transaction_is_debit: transactionIsDebit,
                      transaction_othercosts: transactionOtherCosts,
                      transaction_seller: transactionSeller,
                    }
                  : {}),
                ...(props.type === 'commodity'
                  ? {
                      commodity_brand: commodityBrand,
                      commodity_unit: commodityUnit,
                      commodity_price_per_unit: commodityPricePerUnit,
                      commodity_image_url: commodityImageUrl,
                    }
                  : {}),
              });
              modalCtx?.closeModal();

              Array.from(cache.keys())
                .filter(
                  (x) =>
                    x.includes(`/entity/${props.type}`) ||
                    (props.parent &&
                      x.includes(`/entity/${props.parent?.id}/children`)),
                )
                .forEach((key) => mutate(key));
            } finally {
              setLoading(false);
            }
          }}
        >
          Create
        </Button>
      </Stack>
    </Stack>
  );
}

export function useCreateEntityModal() {
  const { showModal } = useModal();

  return {
    showCreateEntityModal: (type: string, parent?: FinsightEntity) => {
      showModal(
        `New ${type}`,
        <CreateEntityModal type={type} parent={parent} />,
      );
    },
  };
}
