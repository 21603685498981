import {
  Add,
  Apps,
  AutoAwesome,
  EmojiEmotions,
  Gif,
  Link,
  StickyNote2,
  Tag,
} from '@mui/icons-material';
import {
  Avatar,
  Button,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import React from 'react';

export default function ScratchpadPage() {
  return (
    <Stack direction="row" mx={-3} height="100%">
      <Stack flex="0 0 250px" borderRight="1px solid" borderColor="divider">
        <List dense>
          <ListItemButton>
            <ListItemIcon>
              <Tag />
            </ListItemIcon>
            <ListItemText primary="General" />
          </ListItemButton>
          <ListItemButton>
            <ListItemIcon>
              <Tag />
            </ListItemIcon>
            <ListItemText primary="Shed" />
          </ListItemButton>
          <ListItemButton>
            <ListItemIcon>
              <Tag />
            </ListItemIcon>
            <ListItemText primary="Random Links" />
          </ListItemButton>
          <ListItemButton selected>
            <ListItemIcon>
              <Tag />
            </ListItemIcon>
            <ListItemText primary="Lemonade Stand" />
          </ListItemButton>
          <Divider />
          <ListItemButton dense>
            <ListItemIcon>
              <Add />
            </ListItemIcon>
            <ListItemText primary="Add channel" />
          </ListItemButton>
        </List>
      </Stack>
      <Stack flexGrow={1}>
        <Stack flexGrow={1} justifyContent="flex-end">
          <List>
            <ListItem>
              <ListItemIcon>
                <Add />
              </ListItemIcon>
              <ListItemText>
                <Typography color="gray" display="inline">
                  Nasser Akhter created the channel
                </Typography>{' '}
                <Typography fontWeight="bold" display="inline">
                  Lemonade Stand
                </Typography>
              </ListItemText>
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <Link />
              </ListItemIcon>
              <ListItemText>
                <Typography color="gray" display="inline">
                  Nasser Akhter parented this channel to entity
                </Typography>{' '}
                <Typography fontWeight="bold" display="inline">
                  Project: Lemonade Stand
                </Typography>
              </ListItemText>
            </ListItem>
            <ListItem>
              <ListItemAvatar>
                <Avatar>NA</Avatar>
              </ListItemAvatar>
              <Stack>
                <Typography variant="body2" fontWeight="bold">
                  Nasser Akhter
                </Typography>
                <Typography>test</Typography>
              </Stack>
            </ListItem>
            <Divider sx={{ mx: 2 }}>
              <Typography variant="caption">October 1, 2024</Typography>
            </Divider>
            <ListItem>
              <ListItemAvatar>
                <Avatar>NA</Avatar>
              </ListItemAvatar>
              <Stack>
                <Typography variant="body2" fontWeight="bold">
                  Nasser Akhter
                </Typography>
                <Typography
                  sx={{
                    '&::after': {
                      display: 'block',
                      width: '100%',
                      height: 2.3,
                      marginTop: -0.6,
                      borderRadius: 1,
                      background:
                        'linear-gradient(90deg, rgba(255,207,0,1) 0%, rgba(233,0,255,1) 100%)',
                      content: '""',
                    },
                  }}
                >
                  paid $100 for 200 plastic cups from alibaba
                </Typography>
              </Stack>
            </ListItem>
            <Stack
              borderRadius={2}
              ml={9}
              p={0.4}
              boxShadow={1}
              width="fit-content"
              minWidth={400}
              sx={{
                background:
                  'linear-gradient(90deg, rgba(255,207,0,1) 0%, rgba(233,0,255,1) 100%);',
              }}
            >
              <Stack
                borderRadius={1.2}
                p={1}
                bgcolor="background.paper"
                gap={1}
              >
                <Stack direction="row" alignItems="center" gap={1}>
                  <AutoAwesome fontSize="small" color="warning" />
                  <Typography sx={{ color: '#fc568c' }} fontWeight="bold">
                    Data Maid <Typography display="inline">AI</Typography>
                  </Typography>
                </Stack>

                <Typography variant="h6">Create New Transaction?</Typography>
                <Typography>Bought Plastic Cups</Typography>
                <Divider />
                <ListItem dense>
                  <ListItemText primary="200x" sx={{ flexGrow: 0, mr: 2 }} />
                  <ListItemText
                    primary="Plastic Cups"
                    secondary="AliBaba"
                    sx={{ flexGrow: 0, mr: 2 }}
                  />
                  <Divider
                    orientation="horizontal"
                    sx={{ flexGrow: 1, borderStyle: 'dashed' }}
                  />
                  <ListItemText primary="$100" sx={{ flexGrow: 0, ml: 2 }} />
                </ListItem>
                <Divider />
                <Stack direction="row" justifyContent="space-between">
                  <Typography>Subtotal</Typography>
                  <Typography>$100</Typography>
                </Stack>
                <Stack direction="row" justifyContent="space-between">
                  <Typography variant="h6">Total</Typography>
                  <Typography variant="h6">$100</Typography>
                </Stack>
                <Stack direction="row" justifyContent="flex-end">
                  <Button sx={{ color: '#aaa' }}>Dismiss</Button>
                  <Button color="warning">Create</Button>
                </Stack>
              </Stack>
            </Stack>
          </List>
        </Stack>
        <Stack
          direction="row"
          flexShrink={0}
          p={2}
          gap={1}
          borderTop="1px solid"
          borderColor="divider"
        >
          <IconButton>
            <Add />
          </IconButton>
          <TextField fullWidth placeholder="Messsage #general" size="small" />
          <IconButton>
            <Gif />
          </IconButton>
          <IconButton>
            <StickyNote2 />
          </IconButton>
          <IconButton>
            <EmojiEmotions />
          </IconButton>
          <IconButton>
            <Apps />
          </IconButton>
        </Stack>
      </Stack>
    </Stack>
  );
}
