import { TextField, TextFieldProps } from '@mui/material';
import React, { useEffect, useState } from 'react';

type NumberFormatProps = Omit<TextFieldProps, 'value' | 'onChange'> & {
  min?: number;
  max?: number;
  value: number | null;
  endAdornment?: React.ReactNode;
  startAdornment?: React.ReactNode;
  onChange: (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    value: number | null,
  ) => void;
};

export default function NumberInput(props: NumberFormatProps) {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { value, onChange, startAdornment, endAdornment, ...rest } = props;

  const [inputValue, setInputValue] = useState<string | null>(
    value === null ? value : value.toString(),
  );

  if (isNaN(value ?? 0)) {
    throw new Error('NumberInput value must be a number');
  }

  useEffect(() => {
    setInputValue(value === null ? value : value.toString());
  }, [value]);

  return (
    <TextField
      {...rest}
      value={inputValue}
      onChange={(e) => {
        const isNumOnly = e.target.value
          .replaceAll(',', '')
          .match(/^\d*\.?\d*$/g);

        if (isNumOnly) {
          setInputValue(e.target.value);

          const number = parseFloat(e.target.value);

          if (!isNaN(number)) {
            onChange(e, number);
          }
        }
      }}
    />
  );
}
