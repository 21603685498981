"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FinsightEntityCreateRequestSchema = void 0;
var zod_1 = require("zod");
exports.FinsightEntityCreateRequestSchema = zod_1.z.object({
    type: zod_1.z.string(),
    name: zod_1.z.string(),
    description: zod_1.z.string().optional(),
    parent: zod_1.z.string().optional(),
    priority: zod_1.z.number().optional(),
    cost_estimate: zod_1.z.number().optional(),
    cost_maximum: zod_1.z.number().optional(),
    created: zod_1.z.string().datetime().optional(),
    deadline: zod_1.z.string().datetime().optional(),
    status: zod_1.z.string().optional(),
    commodity_brand: zod_1.z.string().optional(),
    commodity_price_per_unit: zod_1.z.number().optional(),
    commodity_unit: zod_1.z.string().optional(),
    commodity_image_url: zod_1.z.string().optional(),
    transaction_amount: zod_1.z.number().optional(),
    transaction_othercosts: zod_1.z.number().optional(),
    transaction_buyer: zod_1.z.string().optional(),
    transaction_seller: zod_1.z.string().optional(),
    transaction_is_debit: zod_1.z.boolean().optional(),
});
