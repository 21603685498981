import { Button, MenuItem, Stack, TextField, Typography } from '@mui/material';
import { DateTimePicker } from '@mui/x-date-pickers-pro';
import { EndpointUrl } from 'api/projects/provider';
import { statusArray, statuses } from 'components/createProject';
import { useCurrentModal, useModal } from 'components/modal';
import NumberInput from 'components/NumberInput';
import { PriorityIcons } from 'components/projectView';
import { Moment } from 'moment';
import React, { useState } from 'react';
import { Project, Task, TaskSchema } from 'shared';

export default function useCreateTask(
  refresh: () => void,
  parent?: Project | Task,
) {
  const { showModal } = useModal();

  return () => {
    if (!parent) return;
    showModal(
      'New Task',
      <CreateTaskModal parent={parent} refresh={refresh} />,
      parent.name,
    );
  };
}

function CreateTaskModal({
  parent,
  refresh,
}: {
  parent: Project | Task;
  refresh: () => void;
}) {
  const ctx = useCurrentModal();

  // new Project().deadline;

  const [taskTemplate, setTaskTemplate] = useState<Task>({
    _id: '',
    budget: null,
    deadline: null,
    description: '',
    name: '',
    commodities: [],
    priority: {
      level: 2,
      prerequisites: [],
    },
    created: new Date(),
    parent: parent
      ? {
          id: parent._id,
          type: parent.type,
        }
      : null,
    parentPaths: [],
    tasks: [],
    transactions: [],
    type: 'task',
    status: 'planning',
    children: [],
  });

  const [deadline, setDeadline] = useState<Moment | null>(null);

  return (
    <Stack gap={2}>
      <Stack gap={3} direction="row">
        <Stack gap={2} flexGrow={1} minWidth={800}>
          <Typography variant="h6">Basic Info</Typography>
          <TextField
            required
            error={!taskTemplate.name.trim().length}
            label="Name"
            value={taskTemplate.name}
            onChange={(e) =>
              setTaskTemplate((prev) => ({
                ...prev,
                name: e.target.value,
              }))
            }
          />
          <TextField
            label="Description"
            multiline
            minRows={10}
            required
            error={!taskTemplate.description.trim().length}
            value={taskTemplate.description}
            onChange={(e) =>
              setTaskTemplate((prev) => ({
                ...prev,
                description: e.target.value,
              }))
            }
          />
          <Stack gap={2} alignItems="center" direction="row">
            <Typography variant="h6">Budgeting</Typography>
            {!!taskTemplate.budget && (
              <Button
                onClick={() =>
                  setTaskTemplate((prev) => ({
                    ...prev,
                    budget: null,
                  }))
                }
              >
                Remove
              </Button>
            )}
          </Stack>
          {taskTemplate.budget ? (
            <Stack gap={1} direction="row">
              <Stack flexGrow={1}>
                <Typography variant="caption" color="text.secondary">
                  Estimated Cost
                </Typography>
                <NumberInput
                  startAdornment="$"
                  value={taskTemplate.budget.estimate}
                  onChange={(_, v) =>
                    setTaskTemplate((prev) => ({
                      ...prev,
                      budget: prev.budget
                        ? {
                            ...prev.budget,
                            estimate: !v || v === 0 ? 0 : v,
                          }
                        : null,
                    }))
                  }
                />
              </Stack>
              <Stack flexGrow={1}>
                <Typography variant="caption" color="text.secondary">
                  Limit
                </Typography>
                <NumberInput
                  startAdornment="$"
                  value={taskTemplate.budget.limit}
                  onChange={(_, v) =>
                    setTaskTemplate((prev) => ({
                      ...prev,
                      budget: prev.budget
                        ? {
                            ...prev.budget,
                            limit: !v || v === 0 ? 0 : v,
                          }
                        : null,
                    }))
                  }
                />
              </Stack>
            </Stack>
          ) : (
            <Stack alignItems="center" justifyItems="flex-start">
              <Typography variant="h6">No budget created</Typography>
              <Typography mb={2}>
                Transactions, estimated cost, and total cost are not available
                until a budget is created.
              </Typography>
              <Button
                onClick={() =>
                  setTaskTemplate((prev) => ({
                    ...prev,
                    budget: {
                      estimate: 0,
                      limit: 0,
                      total: 0,
                    },
                  }))
                }
              >
                Create New Budget
              </Button>
            </Stack>
          )}
        </Stack>
        <Stack gap={2} flexShrink={0} minWidth={250}>
          <Typography variant="h6">Extra Details</Typography>
          <TextField
            select
            label="Priority"
            value={taskTemplate.priority.level}
            onChange={(e) =>
              setTaskTemplate((prev) => ({
                ...prev,
                priority: {
                  level: Number(e.target.value) ?? 2,
                  prerequisites: [],
                },
              }))
            }
          >
            {PriorityIcons.toReversed().map((priority, index) => (
              <MenuItem
                key={priority?.label}
                value={-(index - 2) + 2}
                sx={{
                  boxSizing: 'content-box',
                }}
              >
                <Stack alignItems="center" direction="row" gap={1}>
                  {priority?.icon} {priority?.label}
                </Stack>
              </MenuItem>
            ))}
          </TextField>
          <TextField
            select
            label="Status"
            value={taskTemplate.status}
            onChange={(e) =>
              setTaskTemplate((prev) => ({
                ...prev,
                status: statuses[e.target.value as keyof typeof statuses]
                  ? (e.target.value as keyof typeof statuses)
                  : prev.status,
              }))
            }
          >
            {statusArray.map((status) => (
              <MenuItem key={status} value={status}>
                {statuses[status].title}
              </MenuItem>
            ))}
          </TextField>
          <Stack>
            <DateTimePicker value={deadline} onChange={(v) => setDeadline(v)} />
          </Stack>
        </Stack>
      </Stack>
      <Stack gap={2} direction="row" justifyContent="flex-end">
        <Button color="error" onClick={() => ctx?.closeModal()}>
          Cancel
        </Button>
        <Button
          onClick={async () => {
            try {
              // eslint-disable-next-line @typescript-eslint/no-unused-vars
              const { _id, created, ...rest } = taskTemplate;
              const taskPayload = TaskSchema.parse({
                ...rest,
                created: new Date(),
                deadline: deadline?.toDate(),
              });

              await fetch(`${EndpointUrl}/task`, {
                method: 'POST',
                headers: {
                  'Content-Type': 'application/json',
                },
                body: JSON.stringify(taskPayload),
              });

              ctx?.closeModal();

              setTimeout(() => {
                refresh();
              }, 1000);
            } catch (e) {
              console.dir(e);
            }
          }}
        >
          Create Task
        </Button>
      </Stack>
    </Stack>
  );
}
