import {
  DeleteOutline,
  DragHandle,
  ErrorOutline,
  KeyboardArrowDown,
  KeyboardArrowUp,
  KeyboardDoubleArrowDown,
  KeyboardDoubleArrowUp,
  MoreHoriz,
} from '@mui/icons-material';
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Chip,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Stack,
  Typography,
} from '@mui/material';
import { blue, green, red } from '@mui/material/colors';
import { Budget } from 'api/projects/create';
import { EndpointUrl } from 'api/projects/provider';
import moment from 'moment';
import React from 'react';
import { Project, ProjectRef, ProjectRefSchema, TaskRefSchema } from 'shared';
import { z } from 'zod';

type PriorityIconsType = {
  label: string;
  icon: JSX.Element;
};

export const PriorityIcons: (PriorityIconsType | undefined)[] = [
  {
    label: 'Lowest',
    icon: <KeyboardDoubleArrowDown sx={{ color: '#1199ff' }} />,
  },
  { label: 'Low', icon: <KeyboardArrowDown sx={{ color: '#1199ff' }} /> },
  { label: 'Medium', icon: <DragHandle sx={{ color: 'orange' }} /> },
  { label: 'High', icon: <KeyboardArrowUp sx={{ color: 'red' }} /> },
  { label: 'Highest', icon: <KeyboardDoubleArrowUp sx={{ color: 'red' }} /> },
  { label: 'Immediate', icon: <ErrorOutline sx={{ color: 'red' }} /> },
];

export const PriorityIconsReversed = PriorityIcons.slice()
  .map((x, i) => ({
    ...x,
    value: i,
  }))
  .reverse();

export default function ProjectView({
  projects,
  refresh,
  hideTitle,
}: {
  projects: Project[];
  refresh: () => void;
  hideTitle?: boolean;
}) {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [currentProject, setCurrentProject] = React.useState<Project | null>(
    null,
  );

  return (
    <Stack>
      {!hideTitle && <Typography variant="h4">Projects</Typography>}
      <Stack
        sx={{
          gap: 2,
          display: 'grid',
          gridTemplateColumns: 'repeat(auto-fill, minmax(300px, 1fr))',
          alignItems: 'stretch',
        }}
      >
        {projects.map((project) => (
          <Card
            key={project._id}
            sx={{ display: 'flex', flexDirection: 'column' }}
            onDragOver={(e) => {
              if (e.dataTransfer.types.includes('text/plain/finsight.entity')) {
                e.preventDefault();
              }
            }}
            onDrop={async (e) => {
              try {
                if (
                  e.dataTransfer.types.includes('text/plain/finsight.entity')
                ) {
                  const entityRefRaw = JSON.parse(
                    e.dataTransfer.getData('text/plain/finsight.entity'),
                  );
                  const entityRef = z
                    .union([ProjectRefSchema, TaskRefSchema])
                    .parse(entityRefRaw);

                  if (entityRef.type === 'task') {
                    const parentRef: ProjectRef = {
                      type: 'project',
                      id: project._id,
                    };

                    await fetch(`${EndpointUrl}/task/${entityRef.id}`, {
                      method: 'PATCH',
                      headers: {
                        'Content-Type': 'application/json',
                      },
                      body: JSON.stringify({
                        parent: parentRef,
                      }),
                    });

                    window.location.reload();
                  }
                }
              } catch {}
            }}
          >
            <CardHeader
              title={<Typography variant="h5">{project.name}</Typography>}
              subheader={project.status}
              sx={{ pb: 0 }}
              action={
                <IconButton
                  onClick={(e) => {
                    setCurrentProject(project);
                    setAnchorEl(e.currentTarget);
                  }}
                >
                  <MoreHoriz />
                </IconButton>
              }
            />
            <CardContent sx={{ pt: 0, flexGrow: 1 }}>
              <Stack pb={1} gap={2} direction="row" alignItems="center">
                {moment.isMoment(project.deadline) && (
                  <Chip label={project.deadline.fromNow()} color="primary" />
                )}
                {project.budget &&
                  project.budget?.total > project.budget.limit && (
                    <Chip label="Over Limit" sx={{ my: 1 }} color="error" />
                  )}
              </Stack>
              <Stack gap={1} direction="row" alignItems="center">
                {PriorityIcons[project.priority.level]?.icon}
                {PriorityIcons[project.priority.level]?.label}
              </Stack>
              {project.budget ? (
                <>
                  {project.budget?.total} /{project.budget?.limit}
                  &nbsp;&nbsp;({project.budget?.estimate} est.)
                </>
              ) : null}
              <Typography color="text.secondary">
                {project.description}
              </Typography>
              <br />
              <br />
            </CardContent>
            <Stack p={1}>{/* <Chart budget={project.budget} /> */}</Stack>
            <CardActions>
              <Button href={`/project/${project._id}`}>View</Button>
            </CardActions>
          </Card>
        ))}
      </Stack>
      <Menu
        anchorEl={anchorEl}
        open={!!anchorEl}
        onClose={() => {
          setAnchorEl(null);
          setCurrentProject(null);
        }}
      >
        <MenuItem
          onClick={async () => {
            if (currentProject) {
              await fetch(`${EndpointUrl}/project/${currentProject?._id}`, {
                method: 'DELETE',
              });
            } else {
              console.error('No current project to delete');
            }
            setAnchorEl(null);
            setCurrentProject(null);
            setTimeout(() => refresh(), 1000);
          }}
        >
          <ListItemIcon>
            <DeleteOutline color="error" />
          </ListItemIcon>
          <ListItemText
            primary="Delete"
            sx={{
              color: 'error.main',
            }}
          />
        </MenuItem>
      </Menu>
    </Stack>
  );
}

export function Chart({ budget }: { budget: Budget | undefined }) {
  if (!budget) return null;

  const max = Math.max(
    budget.estimate.rawValue,
    budget.limit?.rawValue ?? 0,
    budget.metrics.total.rawValue,
    1,
  );

  const dataPoints = [
    { value: budget.estimate.rawValue, color: green[600] },
    { value: budget.limit?.rawValue ?? 0, color: red[600] },
    { value: budget.metrics.total.rawValue, color: blue[600] },
  ].sort((a, b) => b.value - a.value);

  return (
    <Box
      sx={{
        height: 25,
        display: 'grid',
        overflow: 'hidden',
        gridTemplateColumns: '1fr',
        gridTemplateRows: '1fr',
        '& > *': {
          gridColumn: 1,
          gridRow: 1,
        },
      }}
    >
      {dataPoints.map((dataPoint, index) => (
        <Box
          key={index}
          style={{
            height: '100%',
            overflow: 'hidden',
            width: `${(dataPoint.value / max) * 100}%`,
            backgroundColor: dataPoint.color,
          }}
        />
      ))}
    </Box>
  );
}
