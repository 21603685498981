import { EndpointUrl } from 'api/endpoint';
import {
  FinsightEntity,
  FinsightEntityCreateRequest,
  FinsightEntityCreateRequestSchema,
  FinsightEntityUpdateRequest,
  FinsightEntityUpdateRequestSchema,
} from 'shared';
import useSWR, { mutate } from 'swr';

export function useAllEntities(type: string) {
  return useSWR(
    EndpointUrl ? `${EndpointUrl}/entity/${type}/all` : null,
    async (url) => {
      const response = await fetch(url);
      if (!response.ok) return null;
      const data = await response.json();
      return data as FinsightEntity[];
    },
  );
}

export function useRootEntities(type: string) {
  return useSWR(
    EndpointUrl ? `${EndpointUrl}/entity/${type}/root` : null,
    async (url) => {
      const response = await fetch(url);
      if (!response.ok) return null;
      const data = await response.json();
      return data as FinsightEntity[];
    },
  );
}

export function useRecentEntities(type: string) {
  return useSWR(
    EndpointUrl ? `${EndpointUrl}/entity/${type}/recent` : null,
    async (url) => {
      const response = await fetch(url);
      if (!response.ok) return null;
      const data = await response.json();
      return data as FinsightEntity[];
    },
  );
}

export function useEntity(id: string | undefined) {
  return useSWR<FinsightEntity | null, string>(
    EndpointUrl && id ? `${EndpointUrl}/entity/${id}` : null,
    async (url: string) => {
      const response = await fetch(url);
      if (!response.ok) return null;
      const data = await response.json();
      return data as FinsightEntity;
    },
  );
}

export function useEntityChildren(id: string | undefined) {
  return useSWR(
    EndpointUrl ? `${EndpointUrl}/entity/${id}/children` : null,
    async (url) => {
      const response = await fetch(url);
      if (!response.ok) return null;
      const data = await response.json();
      return data as FinsightEntity[];
    },
  );
}

export function useCommodities() {
  return useSWR(
    EndpointUrl ? `${EndpointUrl}/entity/commodities` : null,
    async (url) => {
      const response = await fetch(url);
      if (!response.ok) return null;
      const data = await response.json();
      return data as FinsightEntity[];
    },
  );
}

export async function createEntity(entityData: FinsightEntityCreateRequest) {
  console.log(FinsightEntityCreateRequestSchema);
  const payload = FinsightEntityCreateRequestSchema.parse(entityData);
  return await fetch(`${EndpointUrl}/entity`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(payload),
  });
}

export async function deleteEntity(id: string) {
  return await fetch(`${EndpointUrl}/entity/${id}`, {
    method: 'DELETE',
  });
}

export async function updateEntity(
  id: string,
  entityData: FinsightEntityUpdateRequest,
) {
  const payload = FinsightEntityUpdateRequestSchema.parse(entityData);
  const response = await fetch(`${EndpointUrl}/entity/${id}`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(payload),
  });

  mutate(`${EndpointUrl}/entity/${id}`);

  return response;
}
