import { Add, Delete, MoreHoriz, OpenInNew } from '@mui/icons-material';
import {
  Breadcrumbs,
  Card,
  CardActionArea,
  Chip,
  Divider,
  IconButton,
  Link,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  PopoverVirtualElement,
  Stack,
  Typography,
} from '@mui/material';
import { deleteEntity } from 'api/entity';
import { useCreateEntityModal } from 'components/createEntity';
import { PriorityIcons } from 'components/projectView';
import moment from 'moment';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { FinsightEntity } from 'shared';
import { useSWRConfig } from 'swr';

type EntityViewProps = {
  entity?: FinsightEntity;
  viewType?: 'list' | 'card';
  hideType?: boolean;
};

export default function EntityView(props: EntityViewProps) {
  // const { data: entity } = useEntity(props.id);
  const entity = props.entity;
  const [anchorEl, setAnchorEl] = useState<null | PopoverVirtualElement>(null);
  const { cache, mutate } = useSWRConfig();
  const navigate = useNavigate();
  const { showCreateEntityModal } = useCreateEntityModal();

  if (!entity) {
    return null;
  }

  let component: React.ReactNode = null;

  if (props.viewType === 'card') {
    component = (
      <Card
        variant="outlined"
        key={entity.id}
        sx={{ width: 275, height: 250, flexShrink: 0 }}
        onContextMenu={(e) => {
          e.preventDefault();
          setAnchorEl({
            nodeType: 1,
            getBoundingClientRect: () => {
              return {
                x: e.clientX,
                y: e.clientY,
                top: e.clientY,
                left: e.clientX,
                right: e.clientX,
                bottom: e.clientY,
                height: 0,
                width: 0,
                toJSON: () => '',
              };
            },
          });
        }}
      >
        <Stack height="100%">
          <CardActionArea
            disableRipple
            href={`/entity/${entity.id}`}
            sx={{ flexGrow: 1, minHeight: 0, overflow: 'hidden' }}
          >
            <Stack p={2}>
              <Typography variant="h4">{entity.name}</Typography>
              <Stack direction="row" alignItems="center" gap={2}>
                {PriorityIcons[entity.priority]?.icon}
                <Typography>{PriorityIcons[entity.priority]?.label}</Typography>
              </Stack>
              {entity.deadline && (
                <Chip
                  label={'Due ' + moment(entity.deadline).fromNow()}
                  color="error"
                  variant="outlined"
                  size="small"
                  sx={{
                    alignSelf: 'flex-start',
                  }}
                />
              )}
              <Typography>{entity.status}</Typography>
              <Typography>{entity.cost_estimate}</Typography>
              <Typography>{entity.cost_maximum}</Typography>
            </Stack>
          </CardActionArea>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            px={2}
            py={0.5}
            flexShrink={0}
            borderTop="1px solid"
            borderColor="divider"
          >
            <Breadcrumbs>
              {entity.parents?.toReversed().map((parent) => (
                <Link
                  color="text.secondary"
                  key={parent.id}
                  href={`/entity/${parent.id}`}
                >
                  {parent.name}
                </Link>
              ))}
              {entity.parents?.length && <Typography>{entity.name}</Typography>}
            </Breadcrumbs>
            <IconButton
              size="small"
              onClick={(e) => {
                const rect = e.currentTarget.getBoundingClientRect();
                setAnchorEl({
                  nodeType: 1,
                  getBoundingClientRect: () => rect,
                });
              }}
            >
              <MoreHoriz />
            </IconButton>
          </Stack>
        </Stack>
      </Card>
    );
  } else {
    component = (
      <Stack direction="row">
        <ListItemButton
          onContextMenu={(e) => {
            e.preventDefault();
            setAnchorEl({
              nodeType: 1,
              getBoundingClientRect: () => ({
                x: e.clientX,
                y: e.clientY,
                top: e.clientY,
                left: e.clientX,
                right: e.clientX,
                bottom: e.clientY,
                height: 0,
                width: 0,
                toJSON: () => '',
              }),
            });
          }}
          disableRipple
          href={`/entity/${entity.id}`}
        >
          {!props.hideType && <Chip size="small" label={entity.type} />}
          <Stack gap={1} direction="row" alignItems="center" px={2}>
            {PriorityIcons[entity.priority]?.icon}
            <Typography>{PriorityIcons[entity.priority]?.label}</Typography>
          </Stack>
          <Stack direction="row">
            {entity.parents?.length && (
              <>
                <Breadcrumbs>
                  {entity.parents
                    ?.toReversed()
                    .map((parent) => (
                      <Typography key={parent.id}>{parent.name}</Typography>
                    ))}
                </Breadcrumbs>
                <Typography mx={1}>&mdash;</Typography>
              </>
            )}
            <Typography>{entity.name}</Typography>
          </Stack>
        </ListItemButton>
        <IconButton
          onClick={(e) => {
            e.preventDefault();
            const rect = e.currentTarget.getBoundingClientRect();
            setAnchorEl({
              nodeType: 1,
              getBoundingClientRect: () => rect,
            });
          }}
        >
          <MoreHoriz />
        </IconButton>
      </Stack>
    );
  }

  return (
    <>
      {component}
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
      >
        {/* <MenuItem dense onClick={() => navigate(`/entity/${entity.id}`)}>
          <ListItemIcon>
            <OpenInNew />
          </ListItemIcon>
          <ListItemText primary="View Entity" />
        </MenuItem> */}
        {entity.parent && (
          <MenuItem dense onClick={() => navigate(`/entity/${entity.parent}`)}>
            <ListItemIcon>
              <OpenInNew />
            </ListItemIcon>
            <ListItemText primary="View Parent Entity" />
          </MenuItem>
        )}
        {entity.parent && <Divider />}
        <MenuItem
          dense
          onClick={() => showCreateEntityModal('project', entity)}
        >
          <ListItemIcon>
            <Add />
          </ListItemIcon>
          <ListItemText primary="Create Sub-Project" />
        </MenuItem>
        <Divider />
        <MenuItem
          dense
          onClick={async () => {
            setAnchorEl(null);
            await deleteEntity(entity.id);
            if (entity.parent) {
              Array.from(cache.keys())
                .filter((x) => x.includes(`/entity/${entity.parent}/children`))
                .forEach((key) => mutate(key));
            } else {
              Array.from(cache.keys())
                .filter((x) => x.includes(`/entity/${entity.type}/root`))
                .forEach((key) => mutate(key));
            }
          }}
        >
          <ListItemIcon>
            <Delete color="error" />
          </ListItemIcon>
          <ListItemText primary="Delete" sx={{ color: 'error.main' }} />
        </MenuItem>
      </Menu>
    </>
  );
}
