import {
  AccountTreeRounded,
  Assessment,
  AutoAwesome,
  HomeRounded,
  PlaylistAdd,
  ReceiptLong,
  ShoppingBasket,
  StickyNote2,
  TodayOutlined,
} from '@mui/icons-material';
import {
  CssBaseline,
  Drawer,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  Stack,
  ThemeProvider,
  Toolbar,
} from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers-pro';
import { AdapterMoment } from '@mui/x-date-pickers-pro/AdapterMoment';
import { LicenseInfo } from '@mui/x-license';
import { ProjectsProvider } from 'api/projects/provider';
import Header from 'components/header';
import ModalProvider from 'components/modal';
import NotificationProvider from 'components/notify';
import DatamaidPage from 'pages/datamaid';
import EntityPage from 'pages/entity';
import FinsightEntityPage from 'pages/finsightEntity';
import ProjectsPage from 'pages/projects';
import ScratchpadPage from 'pages/scratchpad';
import React from 'react';
import {
  BrowserRouter,
  Outlet,
  Route,
  Routes,
  useLocation,
} from 'react-router-dom';
import { darkTheme, lightTheme } from 'theme';

LicenseInfo.setLicenseKey(
  '05a2ce020d90053b6547efbd692fa010Tz1NaWNyb2FydCxFPTI3MjA5MjA2ODUzMjUsUz1wcmVtaXVtLExNPXN1YnNjcmlwdGlvbixLVj0y',
);

function App() {
  const darkMode = true;
  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <ThemeProvider theme={darkMode ? darkTheme : lightTheme}>
        <CssBaseline />
        <NotificationProvider>
          <ProjectsProvider>
            <ModalProvider>
              <BrowserRouter>
                <Routes>
                  <Route
                    element={
                      <>
                        <Header />
                        <Stack height="100%">
                          <Toolbar />
                          <Stack direction="row" flexShrink={0} flexGrow={1}>
                            <Drawer
                              variant="permanent"
                              sx={{
                                height: '100%',
                                '&, & .MuiPaper-root': {
                                  width: 300,
                                },
                              }}
                            >
                              <Toolbar />
                              <List>
                                <ListSubheader>For You</ListSubheader>
                                {/* <Li icon={<Inbox />} text="Inbox" /> */}
                                <Li
                                  disabled
                                  icon={<HomeRounded />}
                                  text="Home"
                                />
                                <Li
                                  disabled
                                  icon={<Assessment />}
                                  text="Reports"
                                />
                                <Li
                                  disabled
                                  icon={<PlaylistAdd />}
                                  text="Coming Up"
                                />
                                <ListSubheader>
                                  Project Management
                                </ListSubheader>
                                <Li
                                  icon={<AccountTreeRounded />}
                                  text="Projects"
                                  to="/projects"
                                />
                                <Li
                                  icon={<TodayOutlined />}
                                  text="Tasks"
                                  disabled
                                />
                                <ListSubheader>
                                  Financial Management
                                </ListSubheader>
                                <Li
                                  disabled
                                  icon={<ReceiptLong />}
                                  text="Transactions"
                                />
                                <Li
                                  disabled
                                  icon={<ShoppingBasket />}
                                  text="Commodities"
                                />
                                <ListSubheader>Data Management</ListSubheader>
                                <Li
                                  icon={<AutoAwesome />}
                                  text="Data Maid"
                                  to="/datamaid"
                                />
                                <Li
                                  icon={<StickyNote2 />}
                                  text="Scratch Pad"
                                  to="/scratchpad"
                                />
                              </List>
                            </Drawer>
                            <Stack
                              flexGrow={1}
                              height="100%"
                              overflow="auto"
                              px={3}
                            >
                              <Outlet />
                            </Stack>
                          </Stack>
                        </Stack>
                      </>
                    }
                  >
                    <Route path="/*" element={<span></span>} />
                    <Route path="/projects" element={<ProjectsPage />} />
                    <Route
                      path="/task/:id"
                      element={<EntityPage type="task" />}
                    />
                    <Route
                      path="/entity/:id"
                      element={<FinsightEntityPage />}
                    />
                    <Route path="/scratchpad" element={<ScratchpadPage />} />
                    <Route path="/datamaid" element={<DatamaidPage />} />
                  </Route>
                </Routes>
              </BrowserRouter>
            </ModalProvider>
          </ProjectsProvider>
        </NotificationProvider>
      </ThemeProvider>
    </LocalizationProvider>
  );
}

function Li(props: {
  icon?: React.ReactNode;
  to?: string;
  text: string;
  disabled?: boolean;
}) {
  const { pathname } = useLocation();
  return (
    <ListItemButton
      href={props.to ?? ''}
      disabled={props.disabled}
      selected={props.to ? pathname.startsWith(props.to) : false}
    >
      {props.icon && <ListItemIcon>{props.icon}</ListItemIcon>}
      <ListItemText primary={props.text} />
    </ListItemButton>
  );
}

export default App;
