import { Button, MenuItem, Stack, TextField, Typography } from '@mui/material';
import { DateTimePicker } from '@mui/x-date-pickers-pro';
import { EndpointUrl } from 'api/projects/provider';
import { useCurrentModal, useModal } from 'components/modal';
import NumberInput from 'components/NumberInput';
import { PriorityIcons } from 'components/projectView';
import useIsMobile from 'hooks/useIsMobile';
import { Moment } from 'moment';
import React, { useState } from 'react';
import { Project, ProjectSchema, Task } from 'shared';

export default function useCreateProject(
  refresh: () => void,
  parent?: Project | Task,
) {
  const { showModal } = useModal();

  if (parent?.type !== 'task') {
    return () => {
      showModal(
        parent ? 'New Sub-Project' : 'New Project',
        <CreateProjectModal parent={parent} refresh={refresh} />,
        parent ? parent.name : 'Root Level',
      );
    };
  }

  return null;
}

export const statuses = {
  planning: {
    key: 'planning',
    title: 'Planning',
  },
  ready: {
    key: 'ready',
    title: 'Ready',
  },
  active: {
    key: 'active',
    title: 'Active',
  },
  completed: {
    key: 'completed',
    title: 'Completed',
  },
  cancelled: {
    key: 'cancelled',
    title: 'Cancelled',
  },
} as const;

export const statusArray: (keyof typeof statuses)[] = [
  'planning',
  'ready',
  'active',
  // 'completed',
  // 'cancelled',
];

function CreateProjectModal({
  parent,
  refresh,
}: {
  parent?: Project;
  refresh: () => void;
}) {
  const ctx = useCurrentModal();
  const isMobile = useIsMobile();

  // new Project().deadline;

  const [projectTemplate, setProjectTemplate] = useState<Project>({
    _id: '',
    budget: null,
    deadline: null,
    description: '',
    name: '',
    commodities: [],
    priority: {
      level: 2,
      prerequisites: [],
    },
    created: new Date(),
    parent: parent
      ? {
          id: parent._id,
          type: parent.type,
        }
      : null,
    parentPaths: [],
    projects: [],
    tasks: [],
    transactions: [],
    type: 'project',
    status: 'planning',
    children: [],
  });

  const [deadline, setDeadline] = useState<Moment | null>(null);

  return (
    <Stack gap={2}>
      <Stack gap={3} direction={isMobile ? 'column-reverse' : 'row'}>
        <Stack gap={2} flexGrow={1} width={isMobile ? 'auto' : 800}>
          {!isMobile && <Typography variant="h6">Basic Info</Typography>}
          <TextField
            required
            error={!projectTemplate.name.trim().length}
            label="Name"
            value={projectTemplate.name}
            onChange={(e) =>
              setProjectTemplate((prev) => ({
                ...prev,
                name: e.target.value,
              }))
            }
          />
          <TextField
            label="Description"
            multiline
            minRows={10}
            required
            error={!projectTemplate.description.trim().length}
            value={projectTemplate.description}
            onChange={(e) =>
              setProjectTemplate((prev) => ({
                ...prev,
                description: e.target.value,
              }))
            }
          />
          <Stack gap={2} alignItems="center" direction="row">
            <Typography variant="h6">Budgeting</Typography>
            {!!projectTemplate.budget && (
              <Button
                onClick={() =>
                  setProjectTemplate((prev) => ({
                    ...prev,
                    budget: null,
                  }))
                }
              >
                Remove
              </Button>
            )}
          </Stack>
          {projectTemplate.budget ? (
            <Stack gap={1} direction="row">
              <Stack flexGrow={1}>
                <Typography variant="caption" color="text.secondary">
                  Estimated Cost
                </Typography>
                <NumberInput
                  startAdornment="$"
                  value={projectTemplate.budget.estimate}
                  onChange={(_, v) =>
                    setProjectTemplate((prev) => ({
                      ...prev,
                      budget: prev.budget
                        ? {
                            ...prev.budget,
                            estimate: !v || v === 0 ? 0 : v,
                          }
                        : null,
                    }))
                  }
                />
              </Stack>
              <Stack flexGrow={1}>
                <Typography variant="caption" color="text.secondary">
                  Limit
                </Typography>
                <NumberInput
                  startAdornment="$"
                  value={projectTemplate.budget.limit}
                  onChange={(_, v) =>
                    setProjectTemplate((prev) => ({
                      ...prev,
                      budget: prev.budget
                        ? {
                            ...prev.budget,
                            limit: !v || v === 0 ? 0 : v,
                          }
                        : null,
                    }))
                  }
                />
              </Stack>
            </Stack>
          ) : (
            <Stack alignItems="center" justifyItems="flex-start">
              <Typography variant="h6">No budget created</Typography>
              <Typography mb={2}>
                Transactions, estimated cost, and total cost are not available
                until a budget is created.
              </Typography>
              <Button
                onClick={() =>
                  setProjectTemplate((prev) => ({
                    ...prev,
                    budget: {
                      estimate: 0,
                      limit: 0,
                      total: 0,
                    },
                  }))
                }
              >
                Create New Budget
              </Button>
            </Stack>
          )}
        </Stack>
        <Stack gap={2} flexShrink={0} minWidth={250}>
          {!isMobile && <Typography variant="h6">Extra Details</Typography>}
          <Stack gap={1} direction={isMobile ? 'row' : 'column'}>
            <TextField
              select
              fullWidth
              label="Priority"
              value={projectTemplate.priority.level}
              onChange={(e) =>
                setProjectTemplate((prev) => ({
                  ...prev,
                  priority: {
                    level: Number(e.target.value) ?? 2,
                    prerequisites: [],
                  },
                }))
              }
            >
              {PriorityIcons.toReversed().map((priority, index) => (
                <MenuItem
                  key={priority?.label}
                  value={-(index - 2) + 2}
                  sx={{
                    boxSizing: 'content-box',
                  }}
                >
                  <Stack alignItems="center" direction="row" gap={1}>
                    {priority?.icon} {priority?.label}
                  </Stack>
                </MenuItem>
              ))}
            </TextField>
            <TextField
              select
              fullWidth
              label="Status"
              value={projectTemplate.status}
              onChange={(e) =>
                setProjectTemplate((prev) => ({
                  ...prev,
                  status: statuses[e.target.value as keyof typeof statuses]
                    ? (e.target.value as keyof typeof statuses)
                    : prev.status,
                }))
              }
            >
              {statusArray.map((status) => (
                <MenuItem key={status} value={status}>
                  {statuses[status].title}
                </MenuItem>
              ))}
            </TextField>
          </Stack>
          <Stack>
            <DateTimePicker value={deadline} onChange={(v) => setDeadline(v)} />
          </Stack>
        </Stack>
      </Stack>
      <Stack gap={2} direction="row" justifyContent="flex-end">
        <Button color="error" onClick={() => ctx?.closeModal()}>
          Cancel
        </Button>
        <Button
          onClick={async () => {
            try {
              // eslint-disable-next-line @typescript-eslint/no-unused-vars
              const { _id, created, ...rest } = projectTemplate;
              const projectPayload = ProjectSchema.parse({
                ...rest,
                created: new Date(),
                deadline: deadline?.toDate(),
              });

              await fetch(`${EndpointUrl}/project`, {
                method: 'POST',
                headers: {
                  'Content-Type': 'application/json',
                },
                body: JSON.stringify(projectPayload),
              });

              ctx?.closeModal();

              setTimeout(() => {
                refresh();
              }, 1000);
            } catch (e) {
              console.dir(e);
            }
          }}
        >
          Create Project
        </Button>
      </Stack>
    </Stack>
  );
}
