import { Add } from '@mui/icons-material';
import {
  Button,
  List,
  MenuItem,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { useAllEntities, useRecentEntities, useRootEntities } from 'api/entity';
import { useCreateEntityModal } from 'components/createEntity';
import EntityView from 'components/entityView';
import React, { useState } from 'react';
import { FinsightEntity } from 'shared';

export default function ProjectsPage() {
  const { showCreateEntityModal } = useCreateEntityModal();

  const recentProjects = useRecentEntities('project');
  const [filter, setFilter] = useState<'root' | 'all'>('root');
  return (
    <Stack gap={1} pt={2}>
      <Stack direction="row" justifyContent="flex-end">
        <Button
          startIcon={<Add />}
          variant="outlined"
          onClick={() => showCreateEntityModal('project')}
        >
          New Project
        </Button>
      </Stack>
      <Typography variant="h6">Recent Projects</Typography>
      <Stack direction="row" gap={2} minWidth={0} overflow="auto">
        {recentProjects.data?.map((project) => (
          <EntityView entity={project} key={project.id} viewType="card" />
        ))}
      </Stack>
      <Stack direction="row" alignItems="center" justifyContent="space-between">
        <Typography variant="h6">Projects</Typography>
        <TextField
          select
          variant="standard"
          InputProps={{
            startAdornment: (
              <Typography variant="body2" pr={0.5}>
                Filter:
              </Typography>
            ),
          }}
          value={filter}
          onChange={(e) => setFilter(e.target.value as any)}
        >
          <MenuItem value="root">Root</MenuItem>
          <MenuItem value="all">All</MenuItem>
        </TextField>
      </Stack>
      {filter === 'root' ? <RootProjects /> : <AllProjects />}
    </Stack>
  );
}

function RootProjects() {
  const projects = useRootEntities('project');

  if (!projects.data) {
    return null;
  }

  return <ProjectsList projects={projects.data} />;
}

function AllProjects() {
  const projects = useAllEntities('project');

  if (!projects.data) {
    return null;
  }

  return <ProjectsList projects={projects.data} />;
}

function ProjectsList(props: { projects: FinsightEntity[] }) {
  return (
    <List>
      {props.projects?.map((project) => (
        <EntityView hideType entity={project} key={project.id} />
      ))}
    </List>
  );
}
